import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import Section from '../components/Section';
import Img from 'gatsby-image';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-height: 100%;

  h1 {
    font-size: 3em;
    // text-align: center;
  }
`;

const Text = styled.div`
  max-width: 640px;
  margin: 0 auto;
`;

export default class AboutPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Page>
          <Section>
            <Text>
              <h1>О сьемке</h1>
              <p>Я фотографирую людей с любым опытом съёмок.</p>

              <p>
                Активно участвую в разработке концепции съемки, основываясь на
                ваших пожеланиях.
              </p>

              <p>
                Чтобы съёмка прошла приятно и результат был приближён к тем
                работам, которые вы видите в моем портфолио, я составила для вас
                рекомендации и рассказала детальнее про съёмочный процесс.
              </p>

              <h2>Внешний вид</h2>

              <p>
                Выбирайте такую одежду, чтобы вам было удобно и вы чувствовали
                себя красиво. Лучше всего в кадре смотрится одежда из
                натуральных тканей нейтральных оттенков, без ярких принтов и
                надписей. Здорово, если ваш образ будет как бы вне времени.
              </p>

              <p>
                Профессиональный макияж не обязателен, только если он вам нужен
                для уверенности. Я за естественность. Перед съёмкой лучше всего
                хорошо выспаться, чтобы быть отдохнувшим и в хорошем настроении.
              </p>

              <h2>Место съёмки</h2>

              <p>
                Мы можем провести съёмку в студии, на улице или у вас дома.
                Также я могу выехать за пределы города, в этом случае необходимо
                обсудить варианты транспортировки/оплату такси.
              </p>

              <h2>Формат съёмки</h2>

              <p>
                Сначала я предлагаю немного пообщаться и познакомиться, чтобы
                вам было комфортно со мной, затем я начинаю вас снимать.
              </p>

              <p>
                В процессе я поддерживаю мягкий контакт с вами. Если необходимо,
                помогаю сонастроиться и расслабиться с помощью различных техник:
                дыхание, центрирование, разминка для тела. Включаю подходящую
                музыку. Подсказываю и ободряю, способствуя, чтобы вы обретали
                уверенность и свободно проявлялись в кадре.
              </p>

              <p>
                Результат всего этого действа - фотографии, которые останутся с
                вами как вещественное напоминание об этом состоянии.
              </p>

              <p>
                Съёмка в студии проводится как правило при естественном
                освещении. Это наиболее комфортный вид света.
              </p>

              <p>
                Успех съёмки зависит от вашей открытости и доверия фотографу.
                Просто будьте собой и все получится.
              </p>

              <h2>Предоплата и стоимость</h2>

              <p>
                Дата съёмки бронируется при внесении предоплаты в размере 4000
                руб. Предоплата необходима как гарантия вам и мне, что съёмка
                состоится. Форс-мажорные ситуации, конечно, допустимы. Если
                съёмка была отменена клиентом, предоплата не возвращается. Но
                съёмку можно будет перенести на другую удобную дату.
              </p>

              <h2>Результат</h2>

              <p>
                Готовые фото высылаю в электронном виде, ссылкой на Яндекс диск,
                ссылка будет доступна 1 месяц.
              </p>

              <p>
                Пожалуйста, прежде чем забронировать съёмку, обратите внимание:
                я фотографирую только в своём стиле, стремлюсь показать вашу
                естественную красоту, почти не ретуширую фотографии. Однако в
                некоторых случаях я могу деликатно убрать дефекты кожи.
              </p>

              <p>
                Вы можете задать мне любой вопрос перед съёмкой и обсудить
                детали, любым удобным вам способом:
              </p>

              <p>
                <a href="tel:+79031148897">+79031148897</a> (телеграмм, вотсап)
                <br />
                <a href="mailto:saharovata@gmail.com">saharovata@gmail.com</a><br/>
                <a href="https://instagram.com/saharovata">@saharovata</a> Инстаграм
              </p>
            </Text>
          </Section>
        </Page>
      </Layout>
    );
  }
}
